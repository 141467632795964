<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('交易详情')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <div class="bs-panel det-card" v-if="info">
                <div class="det-robot flex-center">
                    <img class="ro-logo" src="../../assets/pictures/avatar-default.png">
                    <div class="flex_bd">{{$t(info.name)}}</div>
                    <div class="det-info">
                        <div class="text-gray">{{$t('收益')}}</div>
                        <div class="value">{{parseFloat(info.profit).toFixed(6)}}</div>
                    </div>
                </div>
                <div class="det-items">
                    <div class="item">{{$t('机器人唯一码')}}：<span class="text-yellow">{{info.password}}</span></div>
                    <div class="item">{{$t('交易完成时间')}}：<span class="text-yellow">{{info.updated_at}}</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                id: 0,
                info: {},
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            getData() {
                this.$axios
                    .get( this.$api_url.order_info.replace(":id", this.id))
                    .then((res) => {
                        this.info = res.data.data;
                    });
            }
        },
        created() {
            if (this.$route.query.id != undefined) {
                this.id = this.$route.query.id;
            }
            this.getData();
        }

    }
</script>
<style scoped>
    .det-card{
        padding:15px;
        background-color: #1a2331;
    }
    .ro-logo{
        width: 36px;
        margin-right: 10px;
        height: 36px;
    }
    .det-info{
        text-align: right;
    }
    .det-items .item{
        margin-top: 10px;
    }
    .det-info .value{
        font-weight: bold;
    }


</style>